.timetable {
	max-width: 130rem;
	margin: 0 auto;
	&::before {
		content: "";
		width: 100%;
		height: 100vh;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		background: $gr_blue-white;
	}
	&__block-nav {
		width: 58rem;
		margin: 3rem auto;
	}
	&__items {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		background: $gr_blue-blackBlue;
		padding: 1rem 3rem;
		transition: all 0.4s ease-in-out 0s;
	}
	&__item {
	}
	&__button {
		color: $white-transparent;
		align-items: center;
		font-weight: 700;
		transition: all 0.4s ease-in-out 0s;
		animation: title-animate 1.3s ease-out;
		&:hover {
			color: white;
			transform: scale(1.009);
			text-shadow: 0px 1rem 1rem $color_after_gradient;
		}
	}
	&__block-ifr {
		margin: 0 1rem;
	}
	&__ifr {
		border-radius: 2rem;
	}
}
