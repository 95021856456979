.auth {
	width: 100%;
	height: 100%;
	&::before {
		content: "";
		width: 100%;
		height: 80vh;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		background: linear-gradient(to bottom, rgb(134, 217, 230) 0%, rgba(125, 185, 232, 0) 100%);
	}
	&__block {
		width: 100%;
		display: flex;
		font-size: 1.8rem;
		justify-content: center;
		align-items: center;
	}
}
.form {
	width: 40rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 3rem;
	div,
	label,
	input,
	p {
		font-size: 1.9rem;
		letter-spacing: 0.1rem;
	}
	&__btn {
		display: inline !important;
		color: inherit !important;
		font-size: 2rem;
		letter-spacing: 0.1rem;
		text-align: center !important;
		border: none !important;
	}
	&__error {
		font-size: 2.3rem !important;
		text-align: center;
		color: red !important;
	}
}

#outlined-basic {
	font-size: 1.8rem !important;
}
