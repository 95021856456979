.svg-1-icon-dims {
	width: 5.7rem;
	height: 6.5rem;
}
.svg-Arrow-dims {
	width: 1.462rem;
	height: 1.821rem;
	// display: inline-block;
	// margin: 0.5rem 0 0 0.4rem;
	transition: all 0.4s ease-in-out 0s;
}
.svg-bar-chart-dims {
	width: 4.3rem;
	height: 4.3rem;
}
.svg-building-government-dims {
	width: 3.9rem;
	height: 4.1rem;
}
.svg-check-dims {
	width: 1.4rem;
	height: 1rem;
}
.svg-child_2-dims {
	width: 3.5rem;
	height: 4.3rem;
}
.svg-close-dims {
	width: 1.8rem;
	height: 1.8rem;
}
.svg-computer_1-dims {
	width: 6.5rem;
	height: 6.8rem;
}
.svg-menu-dims {
	width: 4.8rem;
	height: 4.8rem;
}
.svg-school-logo-dims {
	width: 3.96rem;
	height: 3.749rem;
}
.svg-shapes-dims {
	width: 4.3rem;
	height: 4.3rem;
}
.svg-star-rate-dims {
	width: 4.3rem;
	height: 4.3rem;
}
.svg-subtract-dims {
	width: 3.6rem;
	height: 3.6rem;
}
.svg-user-graduate-dims {
	width: 4.3rem;
	height: 4.3rem;
}
.svg-like-news-dims {
	:last-child {
		transition: all 0.3s ease 0s;
		stroke-width: 5;
		&:hover {
			stroke: rgb(252, 49, 49);
			fill: rgb(252, 49, 49);
		}
	}
}
.svg-like-active {
	path {
		transition: all 0.3s ease 0s;
		stroke-width: 5;
		stroke: rgb(252, 49, 49) !important;
		fill: rgb(252, 49, 49) !important;
	}
}
.svg-views-news-dims {
	stroke-width: 1.4;
}
