//====================================================================================================
.popup {
	-webkit-overflow-scrolling: touch;
	z-index: 100;
	padding: 10px 10px;
	position: fixed;
	top: 0px;
	left: 0;
	width: 100%;
	height: 100%;
	transition: visibility 0.8s ease 0s;
	visibility: hidden;
	overflow: hidden;
	&::before {
		content: "";
		background-color: rgba(0, 0, 0, 0.9);
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0;
		transition: opacity 0.8s ease 0s;
	}
	&.show {
		visibility: visible;
		overflow: auto;
		&::before {
			opacity: 1;
		}
		.popup__body {
			transform: scale(1);
		}
	}
	&._active {
		overflow: auto;
		visibility: visible;
		&::before {
			opacity: 1;
		}
		.popup__body {
			transition: all 0.3s ease 0.2s;
			transform: scale(1);
		}
	}
	// .popup__content
	&__content {
		display: flex;
		position: relative;
		justify-content: center;
		align-items: center;
		z-index: 110;
		flex: 1 1 100%;
		width: 100%;
	}
	// .popup__body
	&__body {
		transform: scale(0);
		transition: all 0.3s ease 0s;
		padding: 40px;
	}
	// .popup__close
	&__close {
		width: 45px;
		height: 45px;
		position: absolute;
		top: 2.6rem;
		right: -2rem;
		cursor: pointer;
		z-index: 130;
	}
	&__img {
		max-width: 100%;
		height: 88vh;
		object-fit: contain;
	}
}
//====================================================================================================
//====================================================================================================
@media (max-width:#{$md3 + px}) {
	.popup {
		display: none;
	}
}
