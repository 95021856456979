.document {
	max-width: 110rem;
	margin: 4rem auto 0 auto;
	&::before {
		content: "";
		width: 100%;
		height: 100vh;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		background: $gr_blue-white;
	}
	&__block {
		position: relative;
		top: 0;
		left: 0;
		margin: 2rem 1rem;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		transition: all 0.3s ease 0s;
	}
	&__box-icon {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 3.5rem;
	}
	&__icon {
		width: 4rem;
		height: 4rem;
		transition: all 0.3s ease 0s;
	}
	&__title {
		flex: 1 1 auto;
		margin-left: 1rem !important;
		font-size: 2rem !important;
		text-align: start !important;
		text-transform: none !important;
		max-width: inherit !important;
	}
	&__ifr-block {
		width: 100%;
		height: 0;
		overflow: hidden;
		border-top: 0.1rem solid black;
		border-bottom: 0.1rem solid black;
		transition: all 0.3s ease 0s;
		animation: ifra 0.6s 1 ease-in-out forwards;
		margin: 1rem 0;
		border-radius: 2rem;
		&__block {
			&:hover {
				background-color: #fff;
			}
		}
	}
	&__ifr-active {
		width: 100%;
		transition: all 0.3s ease 0s;
		animation: ifr 0.6s 1 ease-in-out forwards;
		border-top: 0.1rem solid black;
		border-bottom: 0.1rem solid black;
	}
	@keyframes ifr {
		0% {
			height: 0;
			background-color: rgb(106, 98, 98);
		}
		100% {
			height: 72rem;
			opacity: 1;
		}
	}
	@keyframes ifra {
		0% {
			height: 72rem;
			opacity: 1;
		}
		100% {
			height: 0;
			opacity: 0;
			background-color: rgb(106, 98, 98);
		}
	}
	&__ifr {
		height: 100% !important;
		transition: all 0.3s ease 0s;
	}
	&__active {
		transition: all 0.3s ease 0s;
		transform: rotate(180deg);
	}
	&__btn {
		position: relative;
		z-index: 20;
		height: 100%;
		padding: 1.3rem;
		cursor: pointer;
		background: linear-gradient(to bottom, rgb(255, 78, 78) 0%, rgba(125, 185, 232, 0) 100%) !important;
		border-radius: 0 !important;
		transition: all 0.3s ease 0s !important;
		&:hover {
			transform: scale(1) !important;
			transition: all 0.3s ease-in-out 0s !important;
			background: linear-gradient(to bottom, rgb(255, 24, 24) 0%, rgba(0, 0, 0, 0.5) 100%) !important;
		}
	}
	&__select-btn {
		width: 30rem;
	}
	//style form
	&__form-block {
		width: 100%;
		height: 100%;
		border-top: 0.2rem solid #747474;
	}
}

.progress-bar {
	width: 100%;
	height: 10px;
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 10;
	background: transparent;
}

.progress {
	height: 100%;
	position: relative;
	background: rgb(110, 201, 218);
	background: linear-gradient(
		90deg,
		rgba(110, 201, 218, 1) 0%,
		rgba(0, 255, 106, 1) 20%,
		rgba(110, 201, 218, 1) 34%,
		rgba(0, 255, 106, 1) 53%,
		rgba(110, 201, 218, 1) 71%,
		rgba(0, 255, 106, 1) 91%
	);
	width: 0%;
	opacity: 0;
	transition: all 2s ease-in-out 0s;
}

.document-form {
	height: 100%;
	display: flex;
	padding: 1rem;
	&__row-block {
		margin: 0 0 2rem 0;
		width: 100%;
		height: 100%;
		position: relative;
		top: 0;
		left: 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 2rem;
	}
	&__btn {
		font-size: 1.5rem;
		letter-spacing: 0.1rem;
		color: $black-transparent !important;
	}
}

.input-file {
	width: 0rem;
	height: 0rem;
	opacity: 0;
	position: relative;
	z-index: -50;
}
.input-file-btn {
	padding: 1.2rem 1.7rem !important;
	font-size: 1.5rem;
	letter-spacing: 0.1rem;
	color: $black-transparent !important;
}
.input-text {
	flex: 1 1 auto;
	div,
	label,
	input,
	ul > li,
	p {
		font-size: 1.5rem;
		letter-spacing: 0.1rem;
	}
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root{
	font-size: 1.7rem !important;
}
.toast-container{
	font-size: 1.5rem;
}