.footer {
	flex: 0 0 auto;
	margin-top: 8rem;
	padding: 5rem 0;
	width: 100%;
	background-color: rgb(34, 49, 66);
	color: #fff;
	position: relative;
	bottom: 0;
	left: 0;
	&__block-row {
		max-height: 80%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-evenly;
		gap: 2rem;
	}

	&__column {
		// width: 42rem;
		max-height: 30%;
		display: flex;
		flex-direction: column;
		align-items: center;
		&:first-child {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	&__copyright {
		width: 100%;
		padding: 1rem;
		font-size: 1.6rem;
		background-color: rgba(255, 255, 255, 1);
		color: black;
		position: absolute;
		text-align: center;
		@media (max-width:#{$md3 + px}) {
			font-size: 2.1rem;
		}
	}
}
.column-footer {
	&__logo {
		margin: 3rem 0;
		font-size: 4.2rem;
	}
	&__box-logo {
	}
	&__text-logo {
		text-shadow: 0.2rem 0.2rem 0.6rem rgba(149, 149, 149, 0.8);
		color: rgba(255, 255, 255, 1);
		font-size: 3.7rem;
		font-weight: 900;
		letter-spacing: 0.3rem;
	}

	&__block-title {
		width: 100%;
	}

	&__title {
		animation: title-animate 1.3s ease-out;
	text-transform: uppercase;
	text-align: center;
		span {
			color: $color_title-white-blue;
		}
	}

	&__block-list {
		margin: 1.5rem 0;
	}

	&__list {
	}

	&__item {
		font-size: 1.7rem;
		line-height: 3.7rem;
		@media (max-width:#{$md3 + px}) {
			font-size: 2.6rem;
			line-height: 4.7rem;
		}
		a {
			color: #fff;
			transition: all 0.3s ease 0s;
			&:hover {
				color: rgb(14, 190, 217);
				text-shadow: 0rem 0rem 0.6rem rgba(119, 222, 238, 0.6);
			}
		}
	}
}

.grid-list {
	width: 42rem;
	display: grid;
	grid-template-columns: repeat(auto-fill, 20rem);
	grid-template-rows: repeat(7, auto);
	justify-content: center;
	gap: 1rem;
}
.list-contact {
	max-width: 35rem;
}
