.paginator {
	margin-top: 3rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	li {
		border: 0.1rem solid $color_title;
		border-radius: 50%;
		margin: 0.5rem;
		:hover {
			background: $gr_blue-white;
			color: #fff;
		}
	}
	a {
		font-size: 22px;
		color: $color_title;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		width: 60px;
		height: 60px;
		text-align: center;
		border-radius: 50%;
	}
	&__active {
		background: $gr_blue-white;
		a {
			color: #fff;
		}
	}
}
.previous, .next{
	background: $gr_blue-white;
	a {
		color: #fff;
	}
}