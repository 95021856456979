.categories {
	&__items {
		display: inline-flex;
		flex-wrap: wrap !important;
		gap: 1.3rem;
		margin-bottom: 1.5rem;
		@media (max-width:#{$md4+px}) {
			justify-content: center;
		}
	}
}
.items-categories {
	&__item {
		display: inline-flex;
		justify-content: space-between;
		align-items: center;
		text-align: center;
		transition: all 0.3s ease 0s;
		cursor: pointer;
		border: 0.1rem solid #fff;
		border-radius: 3.5rem;
		padding: 1rem 2rem;
		background: $gr_blue_white;
		color: white;
		// animation: btn-animate 1s ease-out;
		opacity: 0.7;
		background-color: $color_after_gradient;
		box-shadow: 0px 0.5rem 0.5rem $color_after_gradient;
		&:hover {
			opacity: 1;
			background-color: $color_after_gradient;
			transform: scale(1.04);
			box-shadow: 0px 2rem 2rem $color_after_gradient;
		}
	}
	&__item-active {
		opacity: 1;
		background: none;
		background-color: $color_footer;
		transform: scale(1.04);
		box-shadow: 0px 0.5rem 2rem $color_footer;
		&:hover {
			background-color: $color_footer;
			box-shadow: 0px 0.5rem 2rem $color_footer;
		}
	}
	&__delete {
		background: none;
		background-color: rgb(211, 65, 65);
		&:hover {
			background-color: rgb(164, 0, 0);
		}
	}
}
.new-item-categories {
	width: 100%;
	height: 100%;
	padding: 1.5rem;
	border-top: 0.2rem solid #747474;
	&__form {
		display: flex;
		align-items: center;
		gap: 1rem;
	}
	&__text {
		input {
			font-size: 1.5rem;
			letter-spacing: 0.1rem;
		}
	}
	&__btn {
		color: $black-btn !important;
		font-size: 1.5rem;
	}
}
