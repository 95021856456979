//Подключение шрифта
@mixin font($font_name, $file_name, $weight, $style) {
	@font-face {
		font-family: $font_name;
		font-display: swap;
		src: url("../fonts/#{$file_name}.woff") format("woff"), url("../fonts/#{$file_name}.woff2") format("woff2");
		font-weight: #{$weight};
		font-style: #{$style};
	}
}

//Адаптивный шрифт
@mixin adaptiv-font($pcSize, $mobSize, $maxSize) {
	$addSize: $pcSize - $mobSize;
	@if $maxSize==1 {
		//Только если меньше контейнера
		font-size: $pcSize + px;
		@media (max-width: #{$md3 + px}) {
			font-size: calc(#{$mobSize + px} + #{$addSize} * ((100vw - 320px) / #{$md3 - 320}));
		}
	} @else if $maxSize==2 {
		//Только если больше контейнера
		font-size: $pcSize + px;
		@media (min-width: #{$maxWidthContainer + px}) {
			font-size: calc(#{$mobSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidth - 320}));
		}
	} @else {
		//Всегда
		font-size: calc(#{$mobSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidth - 320}));
	}
}

//Адаптивное свойство
@mixin adaptiv-value($indentType, $pcSize, $mobSize, $maxSize) {
	$addSize: $pcSize - $mobSize;
	@if $maxSize==1 {
		//Только если меньше контейнера
		#{$indentType}: $pcSize + rem;
		@media (max-width: #{$md3 + rem}) {
			#{$indentType}: calc(#{$mobSize + rem} + #{$addSize} * ((100vw - 32rem) / #{$md3 - 320}));
		}
	} @else if $maxSize==2 {
		//Только если больше контейнера
		#{$indentType}: $pcSize + rem;
		@media (min-width: #{$maxWidthContainer + rem}) {
			#{$indentType}: calc(#{$mobSize + rem} + #{$addSize} * ((100vw - 32rem) / #{$maxWidth - 320}));
		}
	} @else {
		//Всегда
		#{$indentType}: calc(#{$mobSize + rem} + #{$addSize} * ((100vw - 32rem) / #{$maxWidth - 320}));
	}
}
